import { IPartnerAppSettings } from '../interface/IPartnerAppSettings';

export const prod: IPartnerAppSettings = {
  cdnBaseUrl: 'https://esxp-cdn-prod-aqc7h0hnejh2gqgb.z01.azurefd.net/pagetour',
  serviceEndPoints: [
    {
      clientId: 'fd723931-d8c8-4535-bc98-50e50f472583',
      serviceEndPoint: 'https://pagetourservice.trafficmanager.net',
    },
  ],
  tenantId: '1c4bd415-c658-4c16-9158-203516212576',
  apiUrl: 'https://pagetourservice.trafficmanager.net/api/v1/',
  surveyId: 'ba659328-cd39-4110-963b-4cc2f9d921bf'
};
